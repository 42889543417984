import React, { useRef, useState } from "react";

function PoolList({ pools }) {
  return (
    <div className="pairsContainer">
      {Array.from(pools.values()).map((pool) => (
        <PoolItem key={pool.address} pool={pool} />
      ))}
    </div>
  );
}

function PoolItem({ pool }) {
  const [copySuccess, setCopySuccess] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(pool.address);
    setCopySuccess(true);
    setTimeout(() => {
      setCopySuccess(false);
    }, 2000);
  };
  const getPriceChange = () => {
    return (100 / pool.openPrice) * pool.price - 100;
  };
  return (
    <div className="pairContainer">
      <p className="pairTitle">
        {pool.token0Info === undefined ? '...' : pool.token0Info.symbol}/{pool.token1Info === undefined ? '...' : pool.token1Info.symbol}
      </p>
      <div className="flexContainer">
        <p className="pairLabel"> Pool Address: </p>
        <p onClick={handleCopy} className="copyLabel">
          {" "}
          Copy{" "}
        </p>
        {copySuccess && <span style={{ fontSize: "12px", color: "green", marginLeft: "10px" }}>✅</span>}
      </div>
      <p className="pairLabel">Protocol: {pool.protocol}</p>

      <p className="pairLabel">Open Price: {pool.openPrice}</p>
      <p className="pairLabel">Price: {pool.price}</p>

      <div className="flexContainer">
        <p className="pairLabel">Price Change:</p>
        <p className={`pairLabel ${getPriceChange() > 0 ? "plus" : "minus"}`}>({getPriceChange().toFixed(2)}%)</p>
      </div>

      <p className="pairLabel">Volume: {pool.volume}</p>
      <p className="pairLabel">Swap Count: {pool.swapCount}</p>
      <p className="pairLabel">LP Minted: {Math.floor(pool.poolTokensMinted)}</p>
      <p className="pairLabel">LP Burned: {Math.floor(pool.poolTokensBurned)}</p>
    </div>
  );
}

export default PoolList;
