import * as ethers from "ethers";
import { subscribe, triggerFunctions } from "./logRouter";

const FACTORY_ADDRESS = "0x33128a8fC17869897dcE68Ed026d694621f6FDfD";
const SWAP_ROUTER_ADDRESS = "0x2626664c2603336E57B271c5C0b26F421741e481";
const POSITION_DESCRIPTOR_ADDRESS = "0x91ae842A5Ffd8d12023116943e72A606179294f3";
const POSITION_MANAGER_ADDRESS = "0xC36442b4a4522E871399CD717aBDD847Ab11FE88";
const UNIVERSAL_SWAP_ROUTER_ADDRESS = "0x3fC91A3afd70395Cd496C647d5a6CC9D4B2b7FAD";

const WETH = "0x4200000000000000000000000000000000000006";
const DEAD = "0x000000000000000000000000000000000000dEaD";
const DEAD1 = "0x0000000000000000000000000000000000000000"

const artifacts = {
  UniswapV3Factory: require("@uniswap/v3-core/artifacts/contracts/UniswapV3Factory.sol/UniswapV3Factory.json"),
  NonfungiblePositionManager: require("@uniswap/v3-periphery/artifacts/contracts/NonfungiblePositionManager.sol/NonfungiblePositionManager.json"),
  UniswapV3Pool: require("@uniswap/v3-core/artifacts/contracts/UniswapV3Pool.sol/UniswapV3Pool.json"),
  SwapRouter: require("@uniswap/v3-periphery/artifacts/contracts/interfaces/ISwapRouter.sol/ISwapRouter.json"),
  ERC20: require("@openzeppelin/contracts/build/contracts/ERC20.json"),
  UniswapV2Factory: require("../assets/abis/uniswap/UniswapV2Factory.sol/UniswapV2Factory.json"),
  UniswapV2Pair: require("../assets/abis/uniswap/UniswapV2Pair.sol/UniswapV2Pair.json"),
  UniswapV2Router: require("../assets/abis/uniswap/UniswapV2Router.sol/UniswapV2Router.json"),
};

let map = new Map();
let sortedKeys = [];

function concAddressAndTopic(address, topic) {
  const conc = address + "_" + topic;
  return conc;
}

export const isNullAddress = (address) => {
  return address == DEAD || address == DEAD1;
}
export const connect = async () => {
  const rpc = "wss://base-mainnet.g.alchemy.com/v2/vkzkeZdpfxg394dZYzyOBinbP3UfupqE";
  //const rpc = "wss://base-mainnet.g.alchemy.com/v2/YLrAQAQQ1dvQWy3IEfy08Q4ECqZ4CYol";
  //const rpc = "wss://base-mainnet.g.alchemy.com/v2/im27LTKT_gtu3wxd4VF3MmVO-ISGlHzq";
  //const provider = new ethers.providers.Web3Provider(window.ethereum)
  const provider = new ethers.providers.WebSocketProvider(rpc);
  //const accounts = await provider.send('eth_requestAccounts', [])
  //const chainId = await provider.getNetwork()
  //const signer = provider.getSigner()
  return {
    provider: provider,
    //accounts: accounts,
    //chainId: chainId,
    //signer:signer
  };
};
export const getContracts = async (token0, token1, fee, connection) => {
  const factory = new ethers.Contract(FACTORY_ADDRESS, artifacts.UniswapV3Factory.abi, connection.provider);
  const poolAddress = await factory.getPool(token0, token1, fee);
  const contracts = {
    uniswapPool: new ethers.Contract(poolAddress, artifacts.UniswapV3Pool.abi, connection.provider),
    swapRouter: new ethers.Contract(SWAP_ROUTER_ADDRESS, artifacts.SwapRouter.abi, connection.provider),
    factory: factory,
  };
  return contracts;
};
export const getContract = async (address, abi, provider) => {
  const contract = new ethers.Contract(address, abi, provider);
  return contract;
};
export const subscribeSwapsFromAddress = async (connection, poolAddress, callback) => {
  let pool = new ethers.Contract(poolAddress, artifacts.UniswapV3Pool.abi, connection.provider);
  pool.removeAllListeners();
  const swapFilter = pool.filters.Swap();
  pool.on(swapFilter, (sender, recipient, amount0, amount1, sqrtPriceX96, liquidity, tick, event) => {
    let streamEvent = {
      type: "swap",
      event: event,
      data: {
        seender: sender,
        recipient: recipient,
        amount0: Number(amount0),
        amount1: Number(amount1),
        sqrtPriceX96: sqrtPriceX96,
        liquidity: liquidity,
        tick: tick,
      },
    };
    callback(streamEvent);
  });
};
export const subscribeSwaps = async (contracts, callback) => {
  contracts.uniswapPool.removeAllListeners();

  const swapFilter = contracts.uniswapPool.filters.Swap();
  contracts.uniswapPool.on(swapFilter, (sender, recipient, amount0, amount1, sqrtPriceX96, liquidity, tick, event) => {
    let streamEvent = {
      type: "swap",
      event: event,
      data: {
        seender: sender,
        recipient: recipient,
        amount0: Number(amount0),
        amount1: Number(amount1),
        sqrtPriceX96: sqrtPriceX96,
        liquidity: liquidity,
        tick: tick,
      },
    };
    callback(streamEvent);
  });
};
export const startLogListenerByTopic = async (connection) => {
  const emptyFilter = {};
  connection.provider.on(emptyFilter, (log, event) => {
    if (log) {
      for (let index = 0; index < log.topics.length; index++) {
        const topic = log.topics[index];
        const concOfThisLog = concAddressAndTopic(log.address, topic);
        triggerFunctions(map, concOfThisLog, log);
      }
    }
  });
};
export const startLogListenerByAddress = async (connection) => {
  const emptyFilter = {};
  connection.provider.on(emptyFilter, (log, event) => {
    if (log) {
      if (log.address.toString().toLowerCase() === UNIVERSAL_SWAP_ROUTER_ADDRESS.toLowerCase()) {
        console.log(log.address);
      }
      triggerFunctions(map, log.address, log);
    }
  });
};
export const startLogListenerEmpty = async (connection, callback) => {
  const emptyFilter = {};
  connection.provider.on(emptyFilter, (log, event) => {
    if (log) {
      callback(log);
    }
  });
};
export const getInterface = (abi) => {
  return new ethers.utils.Interface(abi);
};
export const subscribeToTopic = async (contractAddress, topic, iface, callback) => {
  const concFilter = concAddressAndTopic(contractAddress, topic);

  subscribe(map, sortedKeys, concFilter, (log) => {
    var parsedLog = iface.parseLog(log);
    const parsedEvent = {
      raw: parsedLog,
      name: parsedLog.name,
      values: [],
    };
    const keys = Object.keys(parsedLog.args);
    const startIndex = keys.length / 2; // Assuming the first half are numeric indexes

    for (let i = startIndex; i < keys.length; i++) {
      const key = keys[i];
      var parsedValue = {
        name: key,
        value: parsedLog.args[key],
      };
      parsedEvent.values.push(parsedValue);
      parsedEvent[key] = parsedLog.args[key];
    }
    callback(parsedEvent);
  });
};
export const subscribeToAddress = async (contractAddress, iface, callback) => {
  subscribe(map, sortedKeys, contractAddress, (log) => {
    var parsedLog = iface.parseLog(log);
    const parsedEvent = {
      raw: parsedLog,
      name: parsedLog.name,
      values: [],
    };
    const keys = Object.keys(parsedLog.args);
    const startIndex = keys.length / 2; // Assuming the first half are numeric indexes

    for (let i = startIndex; i < keys.length; i++) {
      const key = keys[i];
      var parsedValue = {
        name: key,
        value: parsedLog.args[key],
      };
      parsedEvent.values.push(parsedValue);
      parsedEvent[key] = parsedLog.args[key];
    }
    callback(parsedEvent);
  });
};
export const getUniswapV3PoolInterface = () => {
  const iface = new ethers.utils.Interface(artifacts.UniswapV3Pool.abi);
  return iface;
};
export const getUniswapV3FactoryInterface = () => {
  const iface = new ethers.utils.Interface(artifacts.UniswapV3Factory.abi);
  return iface;
};
export const subscribeToUniswapV3PoolSwaps = (connection, poolAddress, callback) => {
  const contract = new ethers.Contract(poolAddress, artifacts.UniswapV3Pool.abi, connection.provider);
  const swapFilter = contract.filters.Swap();
  contract.on(swapFilter, (sender, recipient, amount0, amount1, sqrtPriceX96, liquidity, tick, event) => {
    let streamEvent = {
      type: "swap",
      event: event,
      data: {
        seender: sender,
        recipient: recipient,
        amount0: Number(amount0),
        amount1: Number(amount1),
        sqrtPriceX96: sqrtPriceX96,
        liquidity: liquidity,
        tick: tick,
      },
    };
    callback(streamEvent);
  });
};
export const getUniswapV2PairInterface = () => {
  const iface = new ethers.utils.Interface(artifacts.UniswapV2Pair.abi);
  return iface;
};
export const getUniswapV2FactoryInterface = () => {
  const iface = new ethers.utils.Interface(artifacts.UniswapV2Factory.abi);
  return iface;
};
export const getERC20Interface = () => {
  const iface = new ethers.utils.Interface(artifacts.ERC20.abi);
  return iface;
};
const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
export const getPoolInfos = async (connection, poolAddress) => {
  try {
    const contract = new ethers.Contract(poolAddress, artifacts.UniswapV3Pool.abi, connection.provider);
    const token0Address = await contract.token0();
    await sleep(500);
    const token1Address = await contract.token1();
    const fee = await contract.fee();
    return {
      poolAddress: poolAddress,
      token0Address: token0Address,
      token1Address: token1Address,
      fee: fee,
    };
  } catch (error) {
    console.log("Error getPoolInfos with poolAddress ", error);
  }
};
export const getTokenInfos = async (connection, tokenAddress) => {
  try {
    if(tokenAddress === WETH){
      return {
        decimals: 18,
        symbol: "WETH",
        name: "Wrapped Ether",
      };
    }
    const contract = new ethers.Contract(tokenAddress, artifacts.ERC20.abi, connection.provider);
    const decimals = await contract.decimals();
    await sleep(500);
    const symbol = await contract.symbol();
    //await sleep(500);
    //const name = await contract.name();
    return {
      decimals: decimals,
      symbol: symbol,
      name: symbol,
    };
  } catch (error) {
    console.log("Error getTokenInfos with tokenAddress ", error);
  }
};
export const startFilteredSub = async (connection, contracts, callback) => {
  //const abi = [filter];
  const iface = new ethers.utils.Interface(artifacts.UniswapV3Pool.abi);
  const swapFilter = contracts.uniswapPool.filters.Swap();
  console.log(swapFilter);

  const concFilter = concAddressAndTopic(swapFilter.address, swapFilter.topics[0]);

  subscribe(map, sortedKeys, concFilter, (log) => {
    var parsedLog = iface.parseLog(log);
    console.log("Matched log: ", parsedLog);
  });

  const emptyFilter = {};
  connection.provider.on(emptyFilter, (log, event) => {
    if (log) {
      for (let index = 0; index < log.topics.length; index++) {
        const topic = log.topics[index];
        const concOfThisLog = concAddressAndTopic(log.address, topic);
        triggerFunctions(map, concOfThisLog, log);
      }
    }
  });
};
export const subscribeTransactions = async (connection, callback) => {
  const filter = {};
  connection.provider.on(filter, (log, event) => {
    let content = "No content at all";
    if (log) {
      content = log;
    } else if (event) {
      content = event;
    }
    console.log("Content: ", content);
  });
};
export const subscribeTransactionsConfirmed = async (connection, callback) => {
  const filter = {};
  connection.provider.on("pending", (tx) => {
    console.log(tx);
    // Emitted when any new pending transaction is noticed
  });
};
export const getPriceOfOneToken0 = (Decimal0, Decimal1, sqrtPriceX96) => {
  const buyOneOfToken0 = (Number(sqrtPriceX96) / 2 ** 96) ** 2 / (10 ** Decimal1 / 10 ** Decimal0); //.toFixed(Decimal1);

  return buyOneOfToken0.toString();
};
export const getPriceOfOneToken1 = (Decimal0, Decimal1, sqrtPriceX96) => {
  console.log("SqrtPriceX96 is", sqrtPriceX96);
  const buyOneOfToken0 = (Number(sqrtPriceX96) / 2 ** 96) ** 2 / (10 ** Decimal1 / 10 ** Decimal0); //.toFixed(Decimal1);
  const buyOneOfToken1 = 1 / buyOneOfToken0; //.toFixed(Decimal0);

  return buyOneOfToken1.toString();
};
export const parse = {
  gweiToEth: (value) => {
    const wei = ethers.utils.parseUnits(value.toString(), "gwei");
    const eth = ethers.utils.formatEther(wei);
    return eth;
  },
  ethToGwei: (value) => {
    const wei = ethers.utils.parseUnits(value.toString(), "ether");
    const gwei = ethers.utils.formatUnits(wei, "gwei");
    return gwei;
  },
  ethToWei: (value) => {
    const wei = ethers.utils.parseUnits(value.toString(), "ether");
    return wei.toString();
  },
  weiToEth: (value) => {
    const weiBn = ethers.BigNumber.from(value);
    const eth = ethers.utils.formatEther(weiBn);
    return eth;
  },
  weiToGwei: (value) => {
    const gwei = ethers.utils.formatUnits(value, "gwei");
    return gwei;
  },
  gweiToWei: (value) => {
    const wei = ethers.utils.parseUnits(value.toString(), "gwei");
    return wei.toString();
  },
  down: (value, decimals) => {
    return ethers.utils.parseUnits(value, decimals).toString();
  },
  up: (value, decimals) => {
    return value / 10 ** decimals;
  },
};
export const getTokenSymbol = async (connection, tokenAddress) => {
  try {
    const contract = new ethers.Contract(tokenAddress, artifacts.ERC20.abi, connection.provider);
    const symbol = await contract.symbol();
    return symbol.toString();
  } catch (error) {
    return "UNKNOWN"
  }
};

function encodePriceSqrt(reserve1, reserve0) {
  let sqr = Math.sqrt(Number(reserve1) / Number(reserve0));
  // Using Bigint for precise integer arithmetic and shifting left by 96 bits
  const priceSqrt = Math.floor(sqr * 2 ** 96);
  return priceSqrt;
}
function decodePriceSqrt(priceSqrt, reserve0Decimals, reserve1Decimals) {
  // Convert Bigint to Number for calculations
  const priceSqrtFloat = Number(priceSqrt) / 2 ** 96;
  const price = priceSqrtFloat ** 2;
  const reserve1 = 10 ** reserve1Decimals;
  const reserve0 = 10 ** reserve0Decimals;
  const reserve0Amount = 1 / ((price * reserve0) / reserve1);
  return Math.floor(reserve0Amount);
}
function getTickAtSqrtPrice(sqrtPriceX96) {
  let tick = Math.floor(Math.log((sqrtPriceX96 / 2 ** 96) ** 2) / Math.log(1.0001));
  return tick;
}
function getTokenAmounts(liquidity, sqrtPriceX96, tickLow, tickHigh, Decimal0, Decimal1) {
  let sqrtRatioA = Math.sqrt(1.0001 ** tickLow);
  let sqrtRatioB = Math.sqrt(1.0001 ** tickHigh);
  let currentTick = getTickAtSqrtPrice(sqrtPriceX96);
  let sqrtPrice = sqrtPriceX96 / 2 ** 96;
  let amount0 = 0;
  let amount1 = 0;
  if (currentTick < tickLow) {
    amount0 = Math.floor(liquidity * ((sqrtRatioB - sqrtRatioA) / (sqrtRatioA * sqrtRatioB)));
  } else if (currentTick >= tickHigh) {
    amount1 = Math.floor(liquidity * (sqrtRatioB - sqrtRatioA));
  } else if (currentTick >= tickLow && currentTick < tickHigh) {
    amount0 = Math.floor(liquidity * ((sqrtRatioB - sqrtPrice) / (sqrtPrice * sqrtRatioB)));
    amount1 = Math.floor(liquidity * (sqrtPrice - sqrtRatioA));
  }

  let amount0Human = (amount0 / 10 ** Decimal0).toFixed(Decimal0);
  let amount1Human = (amount1 / 10 ** Decimal1).toFixed(Decimal1);

  return [amount0Human, amount1Human];
}

export const fetchContractABI = async (address, apiKey) => {
  const url = new URL("https://api.etherscan.io/api");

  // Setting URL search parameters
  const params = {
    module: "contract",
    action: "getabi",
    address: address,
    apikey: apiKey,
  };

  // Appending search parameters to the URL
  Object.keys(params).forEach((key) => url.searchParams.append(key, params[key]));

  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching ABI:", error);
  }
};
