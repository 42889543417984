export const subscribe = (map, sortedKeys, hash, func) => {
  if (map.has(hash)) {
    map.get(hash).push(func);
  } else {
    map.set(hash, [func]);
    // Insert hash into the sortedKeys array at the correct position
    let index = sortedKeys.findIndex((el) => hash < el);
    if (index === -1) {
      // Not found means it is the highest value
      sortedKeys.push(hash);
    } else {
      sortedKeys.splice(index, 0, hash);
    }
  }
  console.log(map)
};
export const unsubscribe = (map, sortedKeys, hash, func) => {
  if (map.has(hash)) {
    let functions = map.get(hash);
    // Filter out the function to be removed
    let filteredFunctions = functions.filter((f) => f !== func);

    if (filteredFunctions.length > 0) {
      // Update the map with the new array of functions if not empty
      map.set(hash, filteredFunctions);
    } else {
      // If the array is empty after removal, delete the hash from the map
      map.delete(hash);
      // Also remove the hash from the sorted keys
      removeHash(sortedKeys, hash);
    }
  }
};
export const removeHash = (sortedKeys, hash) => {
  let index = sortedKeys.indexOf(hash);
  if (index !== -1) {
    sortedKeys.splice(index, 1);
  }
};
export const triggerFunctions = (map, hash, log) => {
  //console.log("Trigger")
  if (map.has(hash)) {
    map.get(hash).forEach((func) => func(log));
  }
};
