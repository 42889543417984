import logo from "./logo.svg";
import "./App.css";
import { test, connect, getContracts, subscribeSwaps, getPriceOfOneToken1, parse, subscribeTransactions, startFilteredSub, startLogListenerByTopic, startLogListenerByAddress, subscribeTransactionsConfirmed } from "./scripts/ethersAdapter";
import { useEffect, useRef, useState } from "react";
import Monitor from "./components/monitor";
import UniversalRouterMonitor from "./components/universalRouterMonitor";

// Token addresses
const UNI_TOKEN = "0xdAC17F958D2ee523a2206206994597C13D831ec7"; //mainnet
const WETH_TOKEN = "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2"; //mainnet
const USDT_TOKEN = "0xdAC17F958D2ee523a2206206994597C13D831ec7";
const USDC_TOKEN = "0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913";
const fee = 500;

function App() {
  useEffect(() => {
    async function runListener() {
      const connection = await connect();
      const contracts = await getContracts(WETH_TOKEN, USDC_TOKEN, fee, connection);
      //await connectToAndPrintSwaps(contracts);
      //await connectToAndPrintTransactions(connection);
      //await connectFilteredSub(connection, contracts);
      //await subscribeTransactionsConfirmed(connection);
    }
    runListener();
  }, []);

  async function connectToAndPrintSwaps(contracts) {
    const sub = await subscribeSwaps(contracts, (swap) => {
      //console.log(swap);
      //const priceForOneEth = getPriceOfOneToken1(6,18,swap.data.sqrtPriceX96)
      let wethSwapQuantity = parse.up(swap.data.amount1, 6);
      let usdtSwapQuantity = parse.weiToEth(swap.data.amount0.toString());
      if (wethSwapQuantity < 0) {
        wethSwapQuantity = wethSwapQuantity * -1;
      }
      if (usdtSwapQuantity < 0) {
        usdtSwapQuantity = usdtSwapQuantity * -1;
      }
      let price = wethSwapQuantity / usdtSwapQuantity;

      console.log(`Swap ${Number(usdtSwapQuantity).toFixed(8)} WETH for ${Number(wethSwapQuantity).toFixed(6)} USDT at ${Number(price).toFixed(6)} USDT/WETH`);
    });
  }
  async function connectToAndPrintTransactions(connection) {
    const sub = await subscribeTransactions(connection, (tx) => {
      //console.log(tx);
    });
  }
  async function connectFilteredSub(connection, contracts) {
    const sub = await startFilteredSub(connection, contracts, (tx) => {
      //console.log(tx);
    });
  }
  return (
    <div className="App">
      <UniversalRouterMonitor/>
    </div>
  );
}

export default App;


//<Monitor />