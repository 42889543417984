import { useEffect, useState } from "react";
import "../App.css";
import {
  connect,
  fetchContractABI,
  getERC20Interface,
  getInterface,
  getPoolInfos,
  getTokenInfos,
  getTokenSymbol,
  getUniswapV2FactoryInterface,
  getUniswapV2PairInterface,
  getUniswapV3FactoryInterface,
  getUniswapV3PoolInterface,
  isNullAddress,
  parse,
  startLogListenerEmpty,
  subscribeSwapsFromAddress,
  subscribeToAddress,
  subscribeToTopic,
  subscribeToUniswapV3PoolSwaps,
} from "../scripts/ethersAdapter";
import { sendDiscordMessage } from "../scripts/discordAdapter";
import PoolList from "./PoolList";

const etherscanKey = "7ZZQ2U1CJV2HUWEU5C6BTYUJQMZWVHK34Q";
const uniswapV2SwapTopic = "0xd78ad95fa46c994b6551d0da85fc275fe613ce37657fb8d5e3d130840159d822";
const uniswapV2AddLiquidityTopic = "0x4c209b5fc8ad50758f13e2e1088ba56a560dff690a1c6fef26394f4c03821c4f";
const uniswapV3SwapTopic = "0xc42079f94a6350d7e6235f29174924f928cc2ac818eb64fed8004e115fbcca67";
const erc20TransferTopic = "0xddf252ad1be2c89b69c2b068fc378daa952ba7f163c4a11628f55a4df523b3ef";
const uniswapV2PairCreatedTopic = "0x0d3648bd0f6ba80134a33ba9275ac585d9d315f0ad8355cddefde31afa28d0e9";
const uniswapV3PairCreatedTopic = "0x783cca1c0412dd0d695e784568c96da2e9c22ff989357a2e8b1d9b2b4e6b7118";

const UNISWAPV2 = "uniswap-v2";
const UNISWAPV3 = "uniswap-v3";

let uniswapv2PairInterface = {};
let uniswapv3FactoryInterface = {};
let erc20Interface = {};
let uniswapv2FactoryInterface = {};
let uniswapv2Pairs = [];

let uniswapv3PoolInterface = {};
let uniswapv3Pools = [];

let connection = {};

const UniversalRouterMonitor = () => {
  const [v2SwapsCount, setV2SwapsCount] = useState(0);
  const [v2PairCreated, setV2PairCreated] = useState(0);
  const [v3PairCreated, setV3PairCreated] = useState(0);
  const [v3SwapsCount, setV3SwapsCount] = useState(0);
  const [erc20TransferCount, setErc20TransferCount] = useState(0);
  const [unclassifiedLogsCount, setUnclassifiedLogsCount] = useState(0);
  const [poolsCount, setPoolsCount] = useState(0);
  const [pools, setPools] = useState(new Map());
  const [swaps, setSwaps] = useState([]);
  const [undefinedPools, setUndefinedPools] = useState([]);
  const [transfers, setTransfers] = useState([]);

  useEffect(() => {
    if (undefinedPools.length < 1) return;

    async function defindeUndefinedPools() {
      for (let index = 0; index < undefinedPools.length; index++) {
        const undefinedPool = undefinedPools[index];
        undefinedPool.token0Info = await getTokenInfos(connection, undefinedPool.token0);
        undefinedPool.token1Info = await getTokenInfos(connection, undefinedPool.token1);

        setPools((prevPools) => {
          if (!prevPools.has(undefinedPool.address)) {
            console.error(`Pool with address ${undefinedPool.address} does not exist.`);
            return prevPools;
          }

          const newPools = new Map(prevPools);
          newPools.set(undefinedPool.address, undefinedPool);
          return newPools;
        });
      }
      setUndefinedPools([]);
    }
    defindeUndefinedPools();
    //console.log(`Pools size in useEffect: ${pools.size}`);
  }, [pools]);

  useEffect(() => {
    if (swaps.length < 1) return;
    //console.log(`Swaps: ${swaps.length} Pools: ${pools.size}`);
    for (let index = 0; index < swaps.length; index++) {
      const unifiedSwapObject = swaps[index];
      const poolAddress = unifiedSwapObject.poolAddress;

      try {
        if (pools.has(poolAddress)) {
          //console.log("unified swap object:", unifiedSwapObject);
          let pool = pools.get(poolAddress);
          let token0SwapQuantity = 0;
          let token1SwapQuantity = 0;

          let buy = false;
          if (unifiedSwapObject.amount0In > 0) {
            token0SwapQuantity = parse.up(unifiedSwapObject.amount0In, pool.token0Info.decimals);
            token1SwapQuantity = parse.up(unifiedSwapObject.amount1Out, pool.token1Info.decimals);
          } else if (unifiedSwapObject.amount0Out > 0) {
            buy = true;
            token0SwapQuantity = parse.up(unifiedSwapObject.amount0Out, pool.token0Info.decimals);
            token1SwapQuantity = parse.up(unifiedSwapObject.amount1In, pool.token1Info.decimals);
          } else {
            console.log("Error adding the swap", unifiedSwapObject);
            return;
          }

          let price = token0SwapQuantity / token1SwapQuantity;
          let priceRounded = Number(price).toFixed(pool.token1Info.decimals);
          if (pool.openPrice == 0) {
            pool.openPrice = priceRounded;
          }
          pool.price = priceRounded;
          if (pool.token0Info.symbol == "WETH" || pool.token0Info.symbol == "USDT") {
            pool.volume += token0SwapQuantity;
          } else {
            pool.volume += token1SwapQuantity;
          }
          pool.swapCount += 1;

          setPools((prevPools) => {
            if (!prevPools.has(poolAddress)) {
              console.error(`Pool with address ${poolAddress} does not exist.`);
              return prevPools;
            }

            const newPools = new Map(prevPools);
            newPools.set(poolAddress, pool);
            return newPools;
          });
        } else {
          //console.log(`Pools: ${pools.size} Swap on: ${poolAddress}`)
        }
      } catch (error) {
        console.log("Not able to add swap", error);
      }
    }
    setSwaps([]);
  }, [swaps]);

  useEffect(() => {
    if (transfers.length < 1) return;
    for (let index = 0; index < transfers.length; index++) {
      const transferLog = transfers[index];

      try {
        if (pools.has(transferLog.address)) {
          let parsedTransfer = uniswapv2PairInterface.parseLog(transferLog);
          let unifiedTransferObject = {
            address: transferLog.address,
            sender: parsedTransfer.args.from,
            receiver: parsedTransfer.args.to,
            value: parsedTransfer.args.value,
          };
          let pool = pools.get(transferLog.address);
          console.log("Transfer for pool token found", unifiedTransferObject);
          if (isNullAddress(unifiedTransferObject.sender)) {
            pool.poolTokensMinted += unifiedTransferObject.value;
            if (isNullAddress(unifiedTransferObject.receiver)) {
              pool.poolTokensBurned += unifiedTransferObject.value;
            }
          } else if (isNullAddress(unifiedTransferObject.receiver)) {
            pool.poolTokensBurned += unifiedTransferObject.value;
          } else {
            console.log("Transfer was not a mint or burn it was ", parsedTransfer);
          }

          setPools((prevPools) => {
            if (!prevPools.has(transferLog.address)) {
              console.error(`Pool with address ${transferLog.address} does not exist.`);
              return prevPools;
            }

            const newPools = new Map(prevPools);
            newPools.set(transferLog.address, pool);
            return newPools;
          });
        } else {
          //console.log(`Transfer of: ${address}`)
        }
      } catch (error) {
        console.log("Not able to add swap", error);
      }
    }
    setTransfers([]);
  }, [transfers]);

  function onStartClick() {
    console.log("start");
    uniswapv3PoolInterface = getUniswapV3PoolInterface();
    uniswapv3FactoryInterface = getUniswapV3FactoryInterface();
    uniswapv2PairInterface = getUniswapV2PairInterface();
    uniswapv2FactoryInterface = getUniswapV2FactoryInterface();
    erc20Interface = getERC20Interface();
    runListener();
  }
  async function runListener() {
    connection = await connect();
    startLogListenerEmpty(connection, (log) => {
      onChainLog(log);
    });
  }

  async function addToPools(poolAddress, protocol, token0 = null, token1 = null) {
    if (!pools.has(poolAddress)) {
      let emptyTokenInfo = {
        decimals: 18,
        symbol: "...",
        name: "...",
      };
      const pool = {
        address: poolAddress,
        protocol: protocol,
        token0: token0,
        token1: token1,
        token0Info: emptyTokenInfo,
        token1Info: emptyTokenInfo,
        price: 0,
        openPrice: 0,
        volume: 0,
        swapCount: 0,
        liquidity: 0,
        priceChange: 0,
        poolTokensMinted: 0,
        poolTokensBurned: 0,
      };
      console.log(`Add pool with address: ${poolAddress} ${pools.size}`);
      setUndefinedPools((prevUndefinedPools) => [...prevUndefinedPools, pool]);
      addPool(pool);
    } else {
      console.log(`Pool with address ${poolAddress} already in state.`);
    }
  }

  const addPool = (pool) => {
    setPools((prevPools) => {
      const newPools = new Map(prevPools);
      if (newPools.has(pool.address)) {
        console.log(`Pool with address ${pool.address} already exists.`);
        return prevPools;
      }
      newPools.set(pool.address, pool);
      //console.log(`Adding pool: ${pool.address}, New size: ${newPools.size}`);
      return newPools;
    });
  };

  async function addLiquidityAddToPool(poolAddress, liquidityEvent) {
    if (pools.has(poolAddress)) {
      let pool = pools.get(poolAddress);
      pool.liquidity = 1;
      setPools((prevPools) => {
        if (!prevPools.has(poolAddress)) {
          console.error(`Pool with address ${poolAddress} does not exist.`);
          return prevPools;
        }

        const newPools = new Map(prevPools);
        newPools.set(poolAddress, pool);
        return newPools;
      });
    }
  }

  async function processSwap(poolAddress, unifiedSwapObject) {
    setSwaps((prevSwaps) => [...prevSwaps, unifiedSwapObject]);
  }
  async function processTransfer(unifiedTransfer) {
    setTransfers((prevTransfers) => [...prevTransfers, unifiedTransfer]);
  }

  function onChainLog(log) {
    try {
      switch (log.topics[0]) {
        //Uniswap V2 Events
        case uniswapV2PairCreatedTopic:
          setV2PairCreated((prevCount) => prevCount + 1);
          var parsedv2PoolCreated = uniswapv2FactoryInterface.parseLog(log);
          //console.log(parsedv2PoolCreated);
          addToPools(parsedv2PoolCreated.args.pair, UNISWAPV2, parsedv2PoolCreated.args.token0, parsedv2PoolCreated.args.token1);
          break;
        case uniswapV2AddLiquidityTopic:
          var parsedv2AddLiquidity = uniswapv2PairInterface.parseLog(log);
          //console.log("Add liq: ", log);
          //console.log(parsedv2AddLiquidity);
          addLiquidityAddToPool(log.address, parsedv2AddLiquidity);
          break;
        case uniswapV2SwapTopic:
          setV2SwapsCount((prevCount) => prevCount + 1);
          var parsedv2Swap = uniswapv2PairInterface.parseLog(log);
          //console.log(log);
          //console.log(parsedv2Swap);
          let unifiedSwapObject = {
            poolAddress: log.address,
            blockHash: log.blockHash,
            amount0In: parsedv2Swap.args.amount0In,
            amount0Out: parsedv2Swap.args.amount0Out,
            amount1In: parsedv2Swap.args.amount1In,
            amount1Out: parsedv2Swap.args.amount1Out,
            sender: parsedv2Swap.args.sender,
            receiver: parsedv2Swap.args.to,
          };
          //console.log(log)
          processSwap(unifiedSwapObject.poolAddress, unifiedSwapObject);
          break;

        //Uniswap V3 Events
        case uniswapV3SwapTopic:
          setV3SwapsCount((prevCount) => prevCount + 1);
          var parsedv3Swap = uniswapv3PoolInterface.parseLog(log);
          //tryAddToPools(log.address);
          break;
        case uniswapV3PairCreatedTopic:
          setV3PairCreated((prevCount) => prevCount + 1);
          var parsedv3PoolCreated = uniswapv3FactoryInterface.parseLog(log);
          console.log("UNISWAP V3 Pair Created!! ", parsedv3PoolCreated);
          //addToPools(parsedv2PoolCreated.pair, UNISWAPV2, parsedv2PoolCreated.token0, parsedv2PoolCreated.token1);
          break;

        //ERC20 Events
        case erc20TransferTopic:
          setErc20TransferCount((prevCount) => prevCount + 1);

          processTransfer(log);
          break;

        //Unclassified
        default:
          setUnclassifiedLogsCount((prevCount) => prevCount + 1);
          break;
      }
    } catch (error) {
      console.log("something went wrong parsing ", error);
      setUnclassifiedLogsCount((prevCount) => prevCount + 1);
    }
  }

  return (
    <div className="container">
      <PoolList pools={pools} />
      <button className="cardButton field" onClick={() => onStartClick()}>
        Start
      </button>

      <h2 className="fieldTitle">{`v2SwapsCount ${v2SwapsCount}`}</h2>
      <h2 className="fieldTitle">{`v2newPair ${v2PairCreated}`}</h2>
      <h2 className="fieldTitle">{`v3SwapsCount ${v3SwapsCount}`}</h2>
      <h2 className="fieldTitle">{`erc20TransferCount ${erc20TransferCount}`}</h2>
      <h2 className="fieldTitle">{`unclassifiedLogsCount ${unclassifiedLogsCount}`}</h2>
    </div>
  );
};

export default UniversalRouterMonitor;
